import * as Yup from "yup"
import { dateRegex, standardRequiredText } from "../../constants"

export const validationSchema = Yup.object().shape({
  title: Yup.string().required(standardRequiredText),
  first_name: Yup.string().required(standardRequiredText),
  surname: Yup.string().required(standardRequiredText),
  worked_for_asda_before: Yup.string().required(standardRequiredText),
  asda_manager: Yup.string(),
  asda_department: Yup.string(),
  asda_start_date: Yup.string().matches(
    dateRegex,
    "Must be a valid date in the format dd/mm/yyyy"
  ),
  asda_end_date: Yup.string().matches(
    dateRegex,
    "Must be a valid date in the format dd/mm/yyyy"
  ),
  understands_absence_procedure: Yup.number().oneOf([1], standardRequiredText),
})
