export const initialValues = {
  title: "",
  first_name: "",
  surname: "",
  worked_for_asda_before: "",
  asda_manager: "",
  asda_department: "",
  asda_start_date: "",
  asda_end_date: "",
  understands_absence_procedure: 0,
}
