import React, { useRef, useState } from "react"
import { Formik } from "formik"
import { Helmet } from "react-helmet"

import { dataURItoBlob } from "../../utils/form-data"
import {
  submitForm,
  handleSubmitResponse,
  jsonToFormData,
} from "../../services"

import AsdaLogo from "../../assets/images/logos/asda-logo.png"
import { CardGrey } from "../../style/containers"
import {
  LCard,
  Col2,
  Col2Constant,
  FieldSet,
  HeaderWithLogo,
} from "../../style/containers"

import Layout from "../../components/layout"
import SButton from "../../components/button"
import Signature from "../../components/Signature"
import Form from "../../components/form/FormHelper"
import { FormikCheckbox, FormikTabs, FormikInput } from "../../components/form"
import { Table, Std, Sth } from "../../style/tables"
import {
  initialValues,
  validationSchema,
} from "../../components/ASDANewStarter"

const AsdaForm = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)

      let res = await submitForm(formData, "")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ASDA New Starter Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form>
              <HeaderWithLogo>
                <h1>ASDA New Starter Form</h1>
                <img src={AsdaLogo} alt="ASDA Logo" />
              </HeaderWithLogo>
              <FieldSet>
                <h2>Your details</h2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="title"
                  label="Title"
                  tabs={[
                    { name: "Mr", label: "Mr" },
                    { name: "Mrs", label: "Mrs" },
                    { name: "Miss", label: "Miss" },
                    { name: "Ms", label: "Ms" },
                  ]}
                />
                <Col2>
                  <FormikInput
                    name="first_name"
                    type="text"
                    label="First name"
                  />
                  <FormikInput name="surname" type="text" label="Surname" />
                </Col2>
                <FormikTabs
                  name="worked_for_asda_before"
                  label="Have you worked for Asda ADC/CDC Washington or any affiliated organisation before?"
                  setFieldValue={setFieldValue}
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                {values.worked_for_asda_before === "Yes" ? (
                  <Col2>
                    <FormikInput
                      name="asda_manager"
                      type="text"
                      label="Manager"
                    />
                    <FormikInput
                      name="asda_department"
                      type="text"
                      label="Department"
                    />
                    <Col2Constant>
                      <FormikInput
                        name="asda_start_date"
                        type="text"
                        label="Start Date"
                      />
                      <FormikInput
                        name="asda_end_date"
                        type="text"
                        label="End Date"
                      />
                    </Col2Constant>
                  </Col2>
                ) : (
                  ""
                )}
              </FieldSet>
              <FieldSet>
                <h2>ASDA Absence Procedure</h2>
                <p>
                  It is important that you attend work for each scheduled shift.
                  If you are unable to attend work due to absence or any other
                  reason you must follow the correct absence procedure.
                </p>
                <p>
                  It is your responsibility to contact Pin Point and inform them
                  of any absence or lateness. You must always contact us
                  yourself unless you are physically unable to do so, and all
                  notifications of absence must be done via phone call.
                </p>
                <p>
                  <b>
                    You must contact your Pin Point representative at least 1
                    hour prior to the start of your shift on the details below:
                  </b>
                </p>
                <Col2>
                  <CardGrey>
                    <b>Lauren Scott</b>
                    <span>
                      Mobile: <a href="tel: 07377414158">07377 414158</a>
                    </span>
                    <span>
                      Tel: <a href="tel: 01912615205">0191 261 5205</a>
                    </span>
                    <span>
                      Email:
                      <a href="mailto: asda@pin-point.co.uk">
                        asda@pin-point.co.uk
                      </a>
                    </span>
                  </CardGrey>
                </Col2>
                <h2>ASDA Pay Rates</h2>
                <Col2>
                  <Table>
                    <thead>
                      <tr>
                        <Sth>Shift</Sth>
                        <Sth>Pay</Sth>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <Std>
                          <b>Day Shift</b>
                        </Std>
                        <Std>£8.21</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Day Shift Overtime</b>
                        </Std>
                        <Std>£9.71</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Day Shift Bank Holiday</b>
                        </Std>
                        <Std>£11.96</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Late Shift</b>
                        </Std>
                        <Std>£8.71</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Late Shift Overtime</b>
                        </Std>
                        <Std>£10.31</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Late Shift Bank Holiday</b>
                        </Std>
                        <Std>£12.71</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Night Shift</b>
                        </Std>
                        <Std>£9.21</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Night Shift Overtime</b>
                        </Std>
                        <Std>£10.91</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Night Shift Bank Holiday</b>
                        </Std>
                        <Std>£13.46</Std>
                      </tr>
                    </tbody>
                  </Table>
                </Col2>
                <h3>Training</h3>
                <p>
                  Colleagues will take part in a company induction lasting 1 day
                  which will take place on site and be lead by the current shift
                  manager. Colleagues will be made aware of the Health &amp;
                  Safety policies, manual handling procedures and will also be
                  given a guided tour of the site and basic LLOP Training.
                </p>
                <h3>Job Spec</h3>
                <p>
                  Duties will include basic picking and packing work using
                  headsets and hand scanners to assist. Colleagues will also be
                  fully trained in the use of Low Level Order Pickers to carry
                  out their duties across the warehouse. Colleagues are expected
                  to hit daily pick targets which will be set by their shift
                  managers.
                </p>
                <h3>PPE</h3>
                <p>Safety boots, yellow hi-vis</p>
                <FormikCheckbox
                  bold={true}
                  label="I have read and understand the ASDA Absence Procedure and pay rates"
                  name="understands_absence_procedure"
                />
              </FieldSet>
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                    <Error style={{ marginTop: "1rem" }} key={e}>
                      {e}
                    </Error>
                  ))
                : null}
              <SButton type="submit" disabled={isSubmitting}>
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default AsdaForm
